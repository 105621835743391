import PropTypes from "prop-types"
import React from "react"
import FooterColumn from "./elements/footer-column"

const Footer = ({ footerColumns }) => (
  <footer className="main-footer" id="kontakt">
    <div className="footer-content">
      <div
        className={
          "st-container footer-columns cols-" +
          Object.keys(footerColumns).length
        }
      >
        {footerColumns.map((item, index) => {
          return <FooterColumn key={index} footerColumn={item.footerColumn} />
        })}
      </div>
    </div>
    <div className="footer-copyrights-bar">
      <div className="st-container">
        <p className="desc-text">
          Realizacja<span className="as-link">FireFly</span>
          Wszelkie prawa zastrzeżone &copy; {new Date().getFullYear()}
        </p>
        <p className="desc-text">
          <a className="as-text" target="_blank" href="/polityka-prywatnosci">
            Polityka prywatności
          </a>
        </p>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  footerColumns: PropTypes.array,
}

Footer.defaultProps = {
  footerColumns: [
    {
      footerColumn: {
        class: "",
        title: "",
        description: "",
      },
    },
  ],
}

export default Footer
