import PropTypes from "prop-types"
import React from "react"

const SimpleBannerWithText = ({ params }) => (
  <section className={
    'st-section-cst simple-banner-with-text-container lazy '
    + params.bannerClass
  }>
    <div className="st-container">
      <div
      className={
        'simple-text-content'
        + (params.textClass? ' ' + params.textClass : '')
      }
      >
        {params.description.map((data, index) => {
          return <p
            key={index}
            className={data.descriptionElement.descriptionClass}
            dangerouslySetInnerHTML={{
              __html: data.descriptionElement.descriptionText
            }}
          />
        })}
        
      </div>
    </div>
  </section>
)

SimpleBannerWithText.propTypes = {
  simpleBannerWithTextParams: PropTypes.object,
}

SimpleBannerWithText.defaultProps = {
  params: {
    bannerClass: ``,
    textClass: ``,
    description: {},
  }
}

export default SimpleBannerWithText
