import React from "react"
import { customGetCookie, customSetCookie } from "../utils/cookies-management"

class CookiesInfoAlert extends React.Component {
  constructor(props) {
    super(props)

    this.cookiesRef = React.createRef()
    this.closeCookies = this.closeCookies.bind(this)
    let stateObjects = {
      removingCookies: false,
    }

    if (customGetCookie("hydroszpak-cookies-accept")) {
      stateObjects.showCookiesAlert = false
    } else {
      stateObjects.showCookiesAlert = true
    }

    this.state = stateObjects
  }

  closeCookies() {
    this.setState(prevState => ({
      removingCookies: true,
    }))
    setTimeout(() => {
      this.setState(prevState => ({
        showCookiesAlert: false,
      }))
    }, 300)

    customSetCookie("hydroszpak-cookies-accept", true, "/", 30)
  }

  correctRenderCookiesAlert() {
    if (this.state.showCookiesAlert) {
      return (
        <div
          className={
            this.state.removingCookies
              ? "cookies-info-alert-box removed"
              : "cookies-info-alert-box"
          }
          ref={this.cookiesRef}
        >
          <div className="cookies-info-alert-box-content">
            <p className="cookies-info-alert-box-text">
              Korzystanie z serwisu oznacza akceptację
              <a href="/polityka-prywatnosci/" target="_blank">
                polityki cookies i polityki prywatności
              </a>
              .
            </p>
          </div>
          <button
            className="cookies-info-alert-box-close-btn"
            onClick={this.closeCookies}
            aria-label="Close"
          ></button>
        </div>
      )
    }

    return null
  }

  render() {
    return this.correctRenderCookiesAlert()
  }
}

export default CookiesInfoAlert
