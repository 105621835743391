import PropTypes from "prop-types"
import React from "react"
import logoSvg from "../../assets/svg/logo_hydroszpak.svg"

const MainBanner = ({ params }) => (
  <section className="st-section-cst main-banner-container lazy">
    <div className="st-container">
      <div className="main-banner-content">
        <div className="image-container">
          <img src={logoSvg} alt="Logo HydroSzpak" />
        </div>
        <h1
          className="header-text"
          dangerouslySetInnerHTML={{
            __html: params.bannerTitle,
          }}
        />
        <div className="btn-container">
          <a
            className="btn-st-1"
            role="button"
            aria-label="Zadzwoń"
            href={params.bannerBtnLink}
            dangerouslySetInnerHTML={{
              __html: params.bannerBtnText,
            }}
          />
        </div>
      </div>
    </div>
  </section>
)

MainBanner.propTypes = {
  params: PropTypes.object,
}

MainBanner.defaultProps = {
  params: {
    bannerTitle: `Instalacje`,
    bannerBtnLink: "",
    bannerBtnText: "",
  },
}

export default MainBanner
