import PropTypes from "prop-types"
import React from "react"

const buildList = (listItems) => (
  <ul className="column-list">
    {listItems.map((item, i) => {
        return (
          <li
            key={item + i}
            dangerouslySetInnerHTML={{
              __html: item
            }}
          />
        )
      })
    }
  </ul>
)

const buildParagraph = (textString) => (
  <p
    className="column-description"
    dangerouslySetInnerHTML={{
      __html: textString
    }}
  />
)

const FooterColumn = ({ footerColumn, allColumnsNumber }) => {
  return (
    <div
      className={
        "footer-column" + (footerColumn.class ? " " + footerColumn.class : "")
      }
    >
      <h4
        className="column-header"
        dangerouslySetInnerHTML={{
          __html: footerColumn.title
        }}
      />
      {Array.isArray(footerColumn.description)?
        buildList(footerColumn.description) :
        buildParagraph(footerColumn.description)
      }
    </div>
  )
}

FooterColumn.propTypes = {
  footerColumn: PropTypes.object,
}

FooterColumn.defaultProps = {
  footerColumn: {
    class: '',
    title: '',
    description: '',
  }
}

export default FooterColumn
