import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import CookiesInfoAlert from "../components/cookies-info-alert"
import Header from "./header"
import Footer from "./footer"
import YAMLData from "../content/siteData.yaml"
import "../assets/scss/main.scss"

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.children = this.props.children
  }

  componentDidMount() {
    const lazyEls = document.querySelectorAll(".lazy")

    const callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          //console.log(entry.target);
          entry.target.classList.remove("lazy")
          observer.unobserve(entry.target)
        }
      })
    }
    const IO = new IntersectionObserver(callback, {
      root: null,
      rootMargin: "50px",
      threshold: 0.1,
    })

    lazyEls.forEach(el => {
      IO.observe(el)
    })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Header siteTitle={data.site.siteMetadata.title} />
            <main className="main-main">{this.children}</main>
            <CookiesInfoAlert />
            <Footer footerColumns={YAMLData.footer} />
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
