import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MainBanner from "../components/sections/main-banner"
import MainText from "../components/sections/main-text"
import SimpleBanner from "../components/sections/simple-banner"
import SimpleBannerWithText from "../components/sections/simple-banner-with-text"
import OfferText from "../components/sections/offer-text"
import YAMLData from "../content/siteData.yaml"

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="HydroSzpak" />
        <MainBanner params={YAMLData.mainBanner} />
        <MainText params={YAMLData.mainText} sectionId="o-nas" />
        <SimpleBanner bannerClass="banner-wrench" />
        <OfferText params={YAMLData.offerText} sectionId="oferta" />
        <SimpleBannerWithText params={YAMLData.simpleBannerWithText} />
      </Layout>
    )
  }
}

export default IndexPage
