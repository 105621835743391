import PropTypes from "prop-types"
import React from "react"

const OfferText = ({ params, sectionId }) => (
  <section className="st-section offer-text-container" id={sectionId}>
    <div className="st-container">
      <div className="offer-text-content">
        <h2
          className="st-header-text"
          dangerouslySetInnerHTML={{
            __html: params.sectionTitle,
          }}
        />
        {params.offerItems.map((item, i) => {
          return (
            <div key={i} className="offer-item">
              <h3 className="offer-header-text">{item.offerItem.offerTitle}</h3>
              <ul className="list-decor">
                {item.offerItem.offerList.map((item, i) => {
                  return (
                    <li
                      key={item + i}
                      dangerouslySetInnerHTML={{
                        __html: item,
                      }}
                    />
                  )
                })}
              </ul>
            </div>
          )
        })}
        
      </div>
    </div>
  </section>
)

OfferText.propTypes = {
  params: PropTypes.object,
  sectionId: PropTypes.string,
}

OfferText.defaultProps = {
  params: {
    offerTitle: `Oferta`,
    offerList: {
      offerTitle: ``,
      offerItems: []
    }
  },
  sectionId: '',
}

export default OfferText
