import PropTypes from "prop-types"
import React from "react"

const MainText = ({ params, sectionId }) => (
  <section className="st-section main-text-container" id={sectionId}>
    <div className="st-container main-text-content">
      {React.createElement(
        params.headerTag,
        {className: "st-header-text",
        dangerouslySetInnerHTML: {
          __html: params.headerText
        }},
      )}
      {params.description.map((description, index) => {
        return (
          <p
            key={index}
            className="st-paragraph-text"
            dangerouslySetInnerHTML={{
              __html: description.descriptionText
            }}
          />
        )
      })}
    </div>
  </section>
)

MainText.propTypes = {
  params: PropTypes.object,
  sectionId: PropTypes.string,
}

MainText.defaultProps = {
  params: {
    sectionId: ``,
    headerTag: ``,
    headerText: ``,
    descriptionText: {},
  },
  sectionId: ``,
}

export default MainText
