import PropTypes from "prop-types"
import React from "react"

const SimpleBanner = ({ bannerClass }) => (
  <section className={
    'st-section-cst simple-banner-container lazy '
    + bannerClass
  }>
  </section>
)

SimpleBanner.propTypes = {
  bannerClass: PropTypes.string,
}

SimpleBanner.defaultProps = {
  bannerClass: ``,
}

export default SimpleBanner
