/**
 * Set cookie.
 *
 * @param {string} cname
 * @param {*} cvalue
 * @param {string} cpath
 * @param {number} exdays
 */
export const customSetCookie = (
  cname,
  cvalue,
  cpath,
  exdays,
  sameSite = "Strict"
) => {
  if (typeof window === "undefined" || !window.document) {
    return
  }
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = "expires=" + d.toUTCString()
  document.cookie =
    cname +
    "=" +
    cvalue +
    ";" +
    expires +
    ";path=" +
    cpath +
    ";SameSite=" +
    sameSite
}

/**
 * Get cookie with provided name.
 *
 * @param {string} cname
 * @returns {string}
 */
export const customGetCookie = cname => {
  if (typeof window === "undefined" || !window.document) {
    return
  }
  const name = cname + "="
  const ca = document.cookie.split(";")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}
