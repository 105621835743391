import React from "react"
import logoSvg from "../assets/svg/logo_hydroszpak.svg"

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = { showMenu: false };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.mainHeader = React.createRef();
    this.scrollToSection = this.scrollToSection.bind(this);
  }

  toggleMenu() {
    this.setState( prevState => ({ showMenu: !prevState.showMenu }));
  }

  scrollToSection = event => {
    event.preventDefault();
    const headerHeight = this.mainHeader.current.offsetHeight;
    const sectionId = event.currentTarget.getAttribute('href');
    //window.location.hash = sectionId;
    const sectionIdName = sectionId.substring(1);
    const sectionElement = document.getElementById(sectionIdName);
    const sectionElementPosition = sectionElement.offsetTop;
    const offsetPosition = sectionElementPosition - headerHeight;
    this.setState({showMenu: false});
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  /* Implement scroll to id here!
  useEffect() {

  }
  */

  render() {

    return (
      <header className="main-header" ref={this.mainHeader}>
        <div className="header-contact-bar">
          <div className="st-container">
            <a className="with-icon icon-phone" href="tel:531272547">
              531 27 25 47
            </a>
            <a className="with-icon icon-mail" href="mailto:biuro@hydroszpak.pl">
              biuro@hydroszpak.pl
            </a>
          </div>
        </div>
        <div className="header-container">
          <div className="st-container">
            <div className="logo-content">
              <a href="/" className="logo">
                <img src={logoSvg} alt="Logo HydroSzpak" />
              </a>
            </div>
            <div className="menu-content">
              <button className={
                'hamburger hamburger--collapse'
                + (this.state.showMenu ? ' is-active' : '')
                }
                type="button"
                aria-label="Menu"
                aria-expanded={this.state.showMenu}
                onClick={this.toggleMenu}>
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav className="main-menu-nav">
                <ul className="menu-list">
                  <li>
                    <a href="#o-nas" onClick={this.scrollToSection}>O&nbsp;nas</a>
                  </li>
                  <li>
                    <a href="#oferta" onClick={this.scrollToSection}>Oferta</a>
                  </li>
                  <li>
                    <a href="#kontakt" onClick={this.scrollToSection}>Kontakt</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
